import React from "react"
import { FaChevronRight } from 'react-icons/fa'
import Button from "./button"
import classNames from 'classnames'
import { Trans } from "react-i18next"

export const PricePlanItem = ({ title, small, price, features = [], className, actions = [], big, priceInfo }) => 
  <div className={ classNames(className || "col-12 col-md-6 col-lg-3") }>
    <div className={ classNames("c-price-plan__item", { big }) }>
      <div className="c-price-plan__item-title">
        <h3>{ title }</h3>
        <p>{ small }</p>
      </div>
      <div className="c-price-plan__item-price">
        { price && <h4>{ price }</h4> }
        { priceInfo && <h6>{ priceInfo }</h6> }
      </div>
      <div className="c-price-plan__item-features">
        {
          features.map((feature, index) => 
            <p key={ index }>
              <Trans
                i18nKey={ feature.labelKey }
                components={ [<small></small>, <strong></strong>] }
                values={{ count: feature.count }}
              />
              { feature.label }
            </p>
          )
        }
      </div>
      <div className="c-price-plan__item-actions">
        {
          actions.map((action, index) =>
            <Button 
              key={ index }
              { ...action }
            />
          )
        }
      </div>
    </div>
  </div>

const PricePlan = ({ plans = [] }) =>
  <div className="row c-price-plan justify-content-center">
    {
      plans.map((p, index) => <PricePlanItem {...p} key={ index } />)
    }
  </div>

export default PricePlan