import React from 'react'

const PageTitle = ({
  small, title, description, id
}) =>
  <div className='row justify-content-center' id={ id }>
    <div className='col-12 col-sm-8 col-lg-6'>
      <div className='c-page-title text-center'>
        <h6>{ small }</h6>
        <h3>{ title }</h3>
        {
          description && <p>{ description }</p>
        }
        <div className="line" />
      </div>
    </div>
  </div>

export default PageTitle