import React from "react"
import { FaChevronRight } from 'react-icons/fa'

export const Step = ({ title, description, link, Icon, className, noChevron }) => 
  <div className={ className || "col-12 col-sm-4 col-md-3" }>
    <div className="c-steps__item">
        <div className="c-steps__item-icon">
          { Icon && <Icon /> }
        </div>
        <h5>{ title }</h5>
        <p>{ description }</p>
        { 
          !noChevron &&
          <div className="c-steps__item-caret">
            <FaChevronRight size={ 30 }/>
          </div>
      }
    </div>
  </div>

const Steps = ({ steps = [] }) =>
  <div className="row c-steps justify-content-between">
    {
      steps.map((s, index) => <Step {...s} key={ index } />)
    }
  </div>

export default Steps