import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Suspense } from "react"
import Button from "./button"
import { useTranslation } from "react-i18next"
import { FaBars } from "react-icons/fa"
import SuspenseWrapper from "./suspense-wrapper"

const Header = ({ siteTitle }) => {
  const { t, i18n } = useTranslation()
  const sections = [
    {
      id: 'home',
      label: 'header.home',
      link: '/#home'
    },
    {
      id: 'features',
      label: 'header.features',
      link: '/#features'
    },
    {
      id: 'how_it_work',
      label: 'header.how_it_work',
      link: '/#how_it_work'
    },
    {
      id: 'api_doc',
      label: 'header.api_doc',
      link: 'https://doc.bi18n.pitbi.be/'
    },
    {
      id: 'price',
      label: 'header.price',
      link: '/#price'
    }
  ]
  return (
    <header className="c-header">
        <div className="c-header__content">
            <div className="container">
                <nav className="justify-content-between">
                  <a className="nav-brand" href="/">
                  </a>
                  <div className="c-header__nav-toggle">
                      <span className="c-header__nav-toggle__toggler">
                        <FaBars size={ 28 }/>
                      </span>
                  </div>
                  <div className="c-header__nav">
                    <ul className="c-header__nav-links"id="nav">
                      {
                        sections.map(s => 
                          <li key={ s.id }>
                            {
                              s.link.match(/^http/) ?
                                <a href={ s.link } target='__blank'>{ t(s.label) }</a>
                              :
                                <Link to={ s.link}>{ t(s.label) }</Link>
                            }
                          </li>   
                        )
                      }
                    </ul>
                    <div className="login-btn-area ml-5 mt-5 mt-lg-0">
                      <Button 
                        label={ t('header.start_btn') }
                      />
                    </div>
                  </div>
                </nav>
            </div>
        </div>
    </header>
  )
}

export default () => SuspenseWrapper(Header)
