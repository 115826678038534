import React from "react"
import { FaCaretRight } from 'react-icons/fa'

export const Feature = ({ title, description, link, Icon, className }) => 
  <div className={ className || "col-12 col-sm-6 col-lg-4" }>
    <div className="c-features__item d-flex">
        <div className="icon-box">
          { Icon && <Icon /> }
        </div>
        <div className="c-features__item-text">
          <h5>{ title }</h5>
          <p>{ description }</p>
          {
            link &&
              <a href={ link.to }>{ link.label } <FaCaretRight /></a>
          }
        </div>
    </div>
  </div>

const Features = ({ features = [] }) =>
  <div className="c-features">
    <div className="container">
      <div className="row">
        {
          features.map((f, index) => <Feature {...f} key={ index } />)
        }
      </div>
    </div>
  </div>

export default Features