import React from "react"
import Button from "./button"
import logo from "../images/logo_small.png"

const Welcome = ({ 
  backgroundSVG,
  title = '',
  subTitle = '',
  actions = [],
  SVG
}) => (
  <section className="c-welcome">
      <div className="bg-img" style={ { backgroundImage: `url(${backgroundSVG})` } }></div>
      <div className="container h-100">
        <div className="row h-100 justify-content-between align-items-center">
          <div className="col-12 col-md-6">
            <div className="">
              <div className="c-welcome__logo">{ <img src={ logo } alt="Bi18n" /> }</div>
              <h2>{ title }</h2>
              <h5>{ subTitle }</h5>
              <div className="c-welcome__actions">
              {
                actions.map((action, index) =>
                  <Button 
                    key={ index }
                    { ...action }
                  />
                )
              }
              </div>
            </div>
          </div>
          <div className="col-10 col-sm-8 col-md-5">
              { SVG && <SVG className="c-welcome__cover"/> }
          </div>
        </div>
      </div>
    </section>
)

export default Welcome


