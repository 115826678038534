import React, { Suspense } from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import i18n from "../lib/i18n"
import { useTranslation, Trans } from "react-i18next"
import welcomeBackground from "../images/hero-back.png"
import WelcomeSVG from "../images/undraw/undraw_Character_drawing_ii11.svg"
import Welcome from "../components/welcome"
import PageTitle from "../components/page-title"
import Features from "../components/features"
import patternImg from "../images/pattern.png"
import bi18nImg from "../images/bi18n2.png"
import { FaLanguage, FaUsers, FaNetworkWired, FaFileExport, FaCode, FaFileImport, FaDesktop, FaCheck } from 'react-icons/fa'
import Steps from "../components/steps"
import PricePlan from "../components/price-plan"
import SuspenseWrapper from "../components/suspense-wrapper"


const buildPriceFeatures = (counts = []) => ([{
  type: 'available',
  labelKey: 'home.price_plan.features.projects',
  count:  counts[0]
},
{
  type: 'available',
  labelKey: 'home.price_plan.features.keys',
  count:  counts[1]
},
{
  type: 'available',
  labelKey: 'home.price_plan.features.collaborators',
  count:  counts[2]
},
{
  type: 'available',
  labelKey: 'home.price_plan.features.api_calls', 
  count:  counts[3]
}])


const IndexPage = () => {

  const { t, i18n } = useTranslation()
  console.log(WelcomeSVG)
  return (
    <Layout>
      <SEO title="Home" />
      <Welcome
        backgroundSVG={ welcomeBackground }
        title={ t('home.welcome.title') }
        subTitle={ t('home.welcome.sub_title') }
        SVG={ WelcomeSVG }
        actions={[
          {
            label: t('home.welcome.action_start'),
            to: '/register',
          },
          {
            label: t('home.welcome.action_more'),
            to: '/#features',
            color: 'default'
          }
        ]}
      />

      <div className='container'>
        <PageTitle
          small={ t('home.about.small') }
          title={ t('home.about.title') }
          id='home'
        />
        <div className="text-center">
          <iframe width="700" height="400" src="https://www.youtube.com/embed/dshIxGRRhG0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
        </div>
      </div>

      <div className='c-dark-section'  style={ { backgroundImage: `url(${patternImg})` } }>
        <div className='container'>
          <PageTitle
            small={ t('home.features.small') }
            title={ t('home.features.title') }
            id='features'
          />
        </div>
        <Features 
          features={[
            {
              title: t('home.features.translate.title'),
              description: t('home.features.translate.description'),
              Icon: FaLanguage
            },
            {
              title: t('home.features.collaboration.title'),
              description: t('home.features.collaboration.description'),
              Icon: FaUsers
            },
            {
              title: t('home.features.api.title'),
              description: t('home.features.api.description'),
              Icon: FaCode
            },
            {
              title: t('home.features.import.title'),
              description: t('home.features.import.description'),
              className: 'col-12 col-sm-6 col-lg-4 offset-lg-2',
              Icon: FaFileImport
            },
            {
              title: t('home.features.export.title'),
              description: t('home.features.export.description'),
              Icon: FaFileExport
            }
          ]}
        />
      </div>
      <div className='container'>
        <PageTitle
          title={ t('home.how_it_work.title') }
          small={ t('home.how_it_work.small') }
          id='how_it_work'
        />
        <Steps
          steps={ [
            {
              title: t('home.how_it_work.step_1.title'),
              description: t('home.how_it_work.step_1.description'),
              Icon: FaCode,
            },
            {
              title: t('home.how_it_work.step_2.title'),
              description: t('home.how_it_work.step_2.description'),
              Icon: FaDesktop,
            },
            {
              title: t('home.how_it_work.step_3.title'),
              description: t('home.how_it_work.step_3.description'),
              Icon: FaCheck,
              noChevron: true
            },
          ] }
        />
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-10'>
            <div className='text-center mb-5 p-large'>
              <Trans 
                i18nKey='home.how_it_work.p'
                components={ [
                  <a href='https://doc.bi18n.io' target='__blank'></a>,
                  <br />,
                  <strong></strong>
                ] }
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 c-large-picture'>
            <img src={ bi18nImg } style={{ borderRadius: '3px 3px 0 0' }}/>
          </div>
        </div>
      </div>
      
      <div className='c-light-session mt-0'>
        <div className='container'>
          <PageTitle
            small={ t('home.price_plan.small') }
            title={ t('home.price_plan.title') }
            id='price'
          />
          <PricePlan
            plans={ [
              {
                title: t('home.price_plan.free.title'),
                small: t('home.price_plan.free.small'),
                price: '0',
                features: buildPriceFeatures([1, 150, 0, 50]),
                actions: [{
                  label: t('home.price_plan.actions.get_started'),
                  link: '/'
                }]
              },
              {
                title: t('home.price_plan.individual.title'),
                small: t('home.price_plan.individual.small'),
                price: t('home.price_plan.features.price', { price: '8' }),
                priceInfo: t('home.price_plan.features.annually_price', { price: '88' }),
                features: buildPriceFeatures([1, 1000, 1, '*']),
                actions: [{
                  label: t('home.price_plan.actions.buy'),
                  link: '/'
                }]
              },
              {
                title: t('home.price_plan.startup.title'),
                small: t('home.price_plan.startup.small'),
                big: true,
                price: t('home.price_plan.features.price', { price: '22' }),
                priceInfo: t('home.price_plan.features.annually_price', { price: '220' }),
                features: buildPriceFeatures([5, '*', 10, '*']),
                actions: [{
                  label: t('home.price_plan.actions.buy'),
                  link: '/'
                }]
              },
              {
                title: t('home.price_plan.entreprise.title'),
                small: t('home.price_plan.entreprise.small'),
                priceInfo: t('home.price_plan.features.price_custom'),
                actions: [{
                  label: t('home.price_plan.actions.contact'),
                  link: '/'
                }]
              }
            ] }
          />
        </div>
      </div>
    </Layout>
  )
}

export default () => SuspenseWrapper(IndexPage)
