import React from 'react'
import classNames from 'classnames'

const Button = ({
  label = '', to='', color = '', className = ''
}) => 
  <a
    href={ to || '#' }
    className={ classNames('btn c-btn', className, color) }
  >
    { label }
  </a>

export default Button